<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Unidades de Medida</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar unidade de medida"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateCapacity">
            Adicionar Unidade de Medida
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, capacities)">
        <div v-if="capacities.data.length" class="w-100">
          <div
            v-for="capacity in capacities.data"
            :key="capacity.id"
            class="w-100 mb--md">
            <getecma-capacity-card
              :capacity="capacity" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhuma unidade de medida encontrada" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateCapacity } from '@/modules/capacity/capacity.routes';
import { fetchCapacities } from '@/modules/capacity/capacity.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaCapacityCard from '@/modules/capacity/components/CapacityCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceCapacities',
  components: {
    GetecmaCapacityCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Unidades de Medida', path: this.$route.path },
      ],
      capacities: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchCapacities();
  },
  methods: {
    isMinLayout,
    fetchCapacities() {
      this.capacities.loading = true;
      fetchCapacities({ limit: this.capacities.pagination.limit, page: this.capacities.pagination.page, search: this.searchQuery, type: '' })
        .then(({ capacities, total_capacities: total }) => {
          if (this.searchQuery === '') {
            this.capacities.data.push(...capacities);
          } else {
            this.capacities.data = capacities;
          }
          Object.assign(this.capacities.pagination, { total: parseInt(total), lastPage: Math.ceil(total / this.capacities.pagination.limit) });
        })
        .catch(() => toastError('Erro ao obter unidades de medida.'))
        .finally(() => { this.capacities.loading = false; });
    },
    onCreateCapacity() {
      goToCreateCapacity(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchCapacities();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.capacities.pagination.page = 1;
      this.capacities.pagination.lastPage = 0;
      this.capacities.data = [];
      this.fetchCapacities();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
