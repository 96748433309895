<template>
  <router-link :to="to" :target="target">
    <slot />
  </router-link>
</template>
<script>
import { CAPACITIES_URL } from '@/modules/capacity/capacity.constants';

export default {
  name: 'GetecmaCapacityLink',
  props: {
    id: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: 'view',
      validator(action) {
        return action && ['view', 'edit'].includes(action);
      },
    },
  },
  computed: {
    to() {
      return {
        name: CAPACITIES_URL[this.action].name,
        path: CAPACITIES_URL[this.action].path,
        params: {
          id: this.id,
        },
      };
    },
  },
};
</script>
